function isValidURL(str: string) {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
}

const elasticIndexNameForbiddenChars = [
  " ",
  '"',
  "*",
  "\\",
  "<",
  "|",
  ",",
  ">",
  "/",
  "?",
  "'",
  ":",
];

export const Validators = {
  Required: {
    Value: [(val: unknown) => val != null || "This field is required"],
    ValueWithCustomMessage: (customMessage: string | boolean) => (val: unknown) => {
      return val != null
        ? true
        : typeof customMessage === "string"
        ? customMessage
        : "This field is required";
    },
    Text: [(val: string) => (val || "").length > 0 || "This field is required"],
    TextWithCustomMessage: (customMessage: string) => (val: string) => {
      return (val || "").length > 0 || (customMessage ?? "This field is required");
    },
    Url: [(val: string) => ((val || "").length > 0 && isValidURL(val)) || "This URL is required"],
    ElasticIndexName: [
      (val: string) =>
        ((val || "").length > 0 &&
          elasticIndexNameForbiddenChars.every((forbiddenChar) => !val.includes(forbiddenChar))) ||
        "This field is required and can not contain spaces or special characters",
    ],
    Number: [
      (val: string | number) =>
        (val !== null && val !== undefined && val != "" && !isNaN(+val)) ||
        "This field is required",
    ],
    File: [
      (v: File | null | undefined) => !!v || "File is required",
      (v: File | null | undefined) => (v && v.size > 0) || "File is required",
    ],
    FileWithMaxSize: (maxSizeInMegaBytes: number) => [
      (v: File | null | undefined) => {
        if (!v || v.size === 0) {
          return `File is required (max file size ${maxSizeInMegaBytes}MB)`;
        }

        const fileSizeInMegaBytes = v.size / 1024 / 1024;

        if (fileSizeInMegaBytes >= maxSizeInMegaBytes) {
          return `The file size can not exceed ${maxSizeInMegaBytes}MB, was ${fileSizeInMegaBytes.toFixed(
            1
          )}MB`;
        }

        return true;
      },
    ],
  },
  Array: {
    Min: (minValue: number) => (val: string) =>
      val?.length >= minValue || `This field has to contain at least ${minValue} items`,
  },
};
