
import { Component, Prop, Vue } from "vue-property-decorator";
import { IngestionType, SourceConfigurationDto } from "@/services/dataservice";

@Component
export default class AppendSettingsComponent extends Vue {
  @Prop() sourceConfiguration: SourceConfigurationDto;

  IngestionType = IngestionType;

  get isObjectIdDisabled() {
    return !!this.sourceConfiguration.id;
  }
}
