import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: {
          base: "#d47527",
        },
        accent: {
          base: "#000B4C",
        },
        error: {
          base: "#6D1099",
        },
        warning: {
          base: "#FFCC00",
        },
        success: {
          base: "#24B324",
        },
        info: {
          base: "#6D1099",
        },
      },
    },
  },
});

export type VuetifyForm = Vue & {
  validate: () => boolean;
  resetValidation: () => void;
  inputs: VuetifyControl[];
};
export type VuetifyControl = Vue & {
  validate: () => boolean;
  hasError: boolean;
  $refs: { input: VuetifyControl & { id?: string } };
};
