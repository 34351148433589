
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

@Component
export default class ConfirmDialog extends Vue {
  @Prop() message: string;
  @Prop() title: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop() yesText: string;
  @Prop() noText: string;

  dialog = false;

  @Emit("confirmed")
  yes() {
    this.dialog = false;
  }

  @Emit("declined")
  no() {
    this.dialog = false;
  }

  openDialog() {
    if (this.disabled) {
      return;
    }
    this.dialog = true;
  }
}
