export const constants = {
  cronExpressions: {
    everyMinute: {
      value: "* * * * *",
      text: "Every minute",
    },
    hourly: {
      value: "0 * * * *",
      text: "Hourly",
    },
    daily: {
      value: "0 0 * * *",
      text: "Daily",
    },
    weekly: {
      value: "0 0 * * 0",
      text: "Weekly",
    },
    monthly: {
      value: "0 0 1 * *",
      text: "Monthly",
    },
    never: {
      value: "0 0 31 2 *",
      text: "Never",
    },
  },

  ClientValidation: {
    DefaultMessage: "Please fill in the required information.",
    DefaultMessageTab: (context: string) =>
      `Please fill in the required information in ${context} tab.`,
  },
};
