
import { Component, Prop, Vue } from "vue-property-decorator";
import FileSourceLocation from "@/views/datasource/ingestion-types/filesource-location.vue";
import { DownloadSettingDto, ManualFileUploadIngestionSettingDto } from "@/services/dataservice";
import { SecureStringContainer } from "@/services";

@Component({
  components: {
    FileSourceLocation,
  },
})
export default class IngestionTypeUrl extends Vue {
  @Prop() downloadSetting: DownloadSettingDto & {
    ingestionSetting: ManualFileUploadIngestionSettingDto;
  };
  @Prop() secureStringContainer: SecureStringContainer;

  onFileUpload(file: File | undefined) {
    this.$emit("onFileUpload", file);
  }
}
