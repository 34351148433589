
import { Component, Prop, Vue } from "vue-property-decorator";
import { Validators } from "@/helpers";
import { v4 as generateGuid } from "uuid";
import { SecureStringContainer } from "@/services";

@Component
export default class SecureString extends Vue {
  @Prop() value: string;
  @Prop() secureStringContainer: SecureStringContainer;
  @Prop() label: string;
  @Prop({ default: true }) isRequired: boolean;
  @Prop() dataCy: string;

  model = "";
  id: string;
  hasPreviousValue = false;

  get rules() {
    return {
      required: this.isRequired ? Validators.Required.Text : [],
    };
  }

  mounted() {
    this.hasPreviousValue = !!this.value;

    this.id = this.value || generateGuid();

    if (this.hasPreviousValue) {
      this.model = "*******";
    }
  }

  onChange(value: string | null) {
    if (value === "" || value === null) {
      delete this.secureStringContainer[this.id];
      this.$emit("input", null);
    } else {
      this.secureStringContainer[this.id] = value;
      this.$emit("input", this.id);
    }
  }
}
