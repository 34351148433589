
import { Component, Vue } from "vue-property-decorator";
import { eventHub } from "@/eventhub";

@Component
export default class ProgressBar extends Vue {
  loading = false;

  created() {
    eventHub.$on("before-request", this.showProgressBar);
    eventHub.$on("after-response", this.hideProgressBar);
  }

  beforeDestroy() {
    eventHub.$off("before-request", this.showProgressBar);
    eventHub.$off("after-response", this.hideProgressBar);
  }

  showProgressBar() {
    this.loading = true;
  }

  hideProgressBar() {
    this.loading = false;
  }
}
