
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DataSource from "@/views/datasource/datasource.vue";
import DataSourceHistoryCompare from "@/views/datasource/datasource-history-compare.vue";
import Tooltip from "@/components/tooltip.vue";
import Confirm from "@/components/confirm.dialog.vue";
import moment from "moment";
import { SourceConfigurationHistoryDto } from "@/services/dataservice";
import { api } from "@/services";

@Component({
  components: {
    DataSource,
    Tooltip,
    Confirm,
    DataSourceHistoryCompare,
  },
})
export default class DataSourceHistory extends Vue {
  @Prop() dataSourceId: string;

  left: SourceConfigurationHistoryDto | null = null;
  right: SourceConfigurationHistoryDto | null = null;

  isLoading = true;

  headers = [
    { text: "Comment message", value: "commentMessage" },
    { text: "Date", value: "timestamp" },
    { text: "User", value: "user" },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      filterable: false,
    },
  ];

  history: SourceConfigurationHistoryDto[] = [];

  async created() {
    await this.getHistory();
  }

  compareWithPrevious(item: SourceConfigurationHistoryDto) {
    const selectedIndex = this.history.findIndex((h) => h === item);

    this.right = item;
    this.left = this.history[selectedIndex + 1];
  }

  async clearComparison() {
    this.left = null;
    this.right = null;
    await this.getHistory();
  }

  formatDate(dateTime: string | null) {
    if (!dateTime) {
      return null;
    }

    return moment(dateTime).local().format("DD MMM YYYY, HH:mm");
  }

  @Watch("dataSourceId")
  async getHistory() {
    this.isLoading = true;

    let response = await api.dataservice.dataSourceService.getHistory(this.dataSourceId);

    if (response.isFailure) {
      return;
    }

    this.history = response.result;
    this.isLoading = false;
  }
}
