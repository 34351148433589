
import { Component, Prop, Vue } from "vue-property-decorator";
import { v4 as generateGuid } from "uuid";
import { SecureStringContainer } from "@/services";

@Component
export default class SecureFile extends Vue {
  @Prop() value: string | null | undefined;
  @Prop() fileName: string | null | undefined;
  @Prop() secureStringContainer: SecureStringContainer;
  @Prop() label: string;
  @Prop({ default: true }) isRequired: boolean;
  @Prop() dataCy: string;

  model: { size: number; name: string } | null = null;
  id: string;
  hasPreviousValue = false;

  get rules() {
    return {
      required: this.isRequired
        ? [
            (v: File | null) => !!v || "File is required",
            (v: File | null) => (v && v.size > 0) || "File is required",
          ]
        : [],
    };
  }

  mounted() {
    this.hasPreviousValue = !!this.value;

    this.id = this.value ?? generateGuid();

    if (this.hasPreviousValue) {
      this.model = { size: 1, name: this.fileName! };
    }
  }

  async onChange(file: File | undefined) {
    if (!file) {
      delete this.secureStringContainer[this.id];
      this.$emit("input", null);
      this.$emit("onFileUploaded", null);
    } else {
      this.secureStringContainer[this.id] = await this.toBase64(file);
      this.$emit("input", this.id);
      this.$emit("onFileUploaded", file);
    }
  }

  toBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileContentBase64 = (reader.result as string).split(",")[1];
        resolve(fileContentBase64);
      };
      reader.onerror = reject;
    });
  }
}
