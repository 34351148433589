
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DatahubIngestionSettingDto,
  CustomIngestionSettingDto,
  IngestionType,
  DataFormatType,
  JsonDataFormatSettingDto,
  DownloadSettingDto,
} from "@/services/dataservice";
import IngestionTypeDatahubComponent from "@/views/datasource/ingestion-types/ingestion-type-datahub.vue";

@Component({
  components: {
    IngestionTypeDatahubComponent,
  },
})
export default class IngestionTypeETars extends Vue {
  @Prop() downloadSettings: DownloadSettingDto & {
    ingestionSetting: CustomIngestionSettingDto & {
      ingestionSettings: {
        summaryEndpoint: DatahubIngestionSettingDto;
        detailsEndpoint: DatahubIngestionSettingDto;
      };
    };
  };

  async created() {
    this.downloadSettings.ingestionSetting.ingestionSettings ??= {
      summaryEndpoint: {
        dataProviderId: "",
        dataSourceId: "",
        tenantId: "",
        type: IngestionType.Datahub,
      },
      detailsEndpoint: {
        dataProviderId: "",
        dataSourceId: "",
        tenantId: "",
        type: IngestionType.Datahub,
      },
    };

    if (this.downloadSettings.dataFormatSetting.type !== DataFormatType.Json) {
      this.downloadSettings.dataFormatSetting = {
        type: DataFormatType.Json,
      } as JsonDataFormatSettingDto;
    }
  }
}
