
import { Component, Vue } from "vue-property-decorator";
import DataSource from "@/views/datasource/datasource.vue";
import DataSourceHistory from "@/views/datasource/datasource-history.vue";
import Tooltip from "@/components/tooltip.vue";
import Confirm from "@/components/confirm.dialog.vue";
import moment from "moment";
import { constants } from "@/services/constants";
import { IngestionType, SourceConfigurationListItemDto } from "@/services/dataservice";

import { api } from "@/services";

@Component({
  components: {
    DataSource,
    Tooltip,
    Confirm,
    DataSourceHistory,
  },
})
export default class DataSources extends Vue {
  search = "";
  headers = [
    { text: "Name", value: "name" },
    { text: "Logs", value: "logs", sortable: false, width: "5%" },
    { text: "Run", value: "run", sortable: false, width: "5%" },
    { text: "Status", value: "lastState" },
    { text: "Last execution", value: "lastExecution" },
    { text: "Next execution", value: "nextExecution" },
    { text: "Update schedule", value: "updateSchedule" },
    { text: "Restriction", value: "isPublic" },
    { text: "Mapping types", value: "mappingTypesLabel" },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      filterable: false,
    },
    { text: "", value: "data-table-expand" },
  ];
  sortBy = "name";
  sortDesc = false;
  dataSources: SourceConfigurationItemViewModel[] = [];
  showDialog = false;
  dataSourceId: string | null = null;
  updateIntervalId: number;
  updateIntervalInSeconds = 5;

  $refs: { dataSourceHistoryComponent?: DataSourceHistory };

  async mounted() {
    await this.getAll();
    this.updateIntervalId = setInterval(() => {
      this.getAll();
    }, this.updateIntervalInSeconds * 1000);
  }

  destroyed() {
    clearInterval(this.updateIntervalId);
  }

  editDataSource(dataSource: SourceConfigurationListItemDto | null) {
    this.dataSourceId = dataSource?.id || null;
    this.showDialog = true;
  }

  async getAll() {
    let response = await api.dataservice.dataSourceService.getAll([]);

    if (response.isFailure) {
      return;
    }

    const dataSources: SourceConfigurationItemViewModel[] = response.result.map((ds) => ({
      ...ds,
      state: this.getState(ds),
      lastStateColor: this.getChipColor(ds),
      lastExecution: this.formatDate(ds.lastExecution),
      lastExecutionFromNow: this.fromNow(ds.lastExecution),
      nextExecution: this.formatDate(ds.nextExecution),
      nextExecutionFromNow: this.fromNow(ds.nextExecution),
      cronExpressionDescription: this.getCronExpressionDescription(ds.cronExpression),
      tooltipHtml:
        (ds.description ? ds.description + "<br><br>" : "") +
        (ds.tags.length === 0 ? "" : "Tags: " + ds.tags.join(", ")),
      canBeTriggered:
        !["Processing", "Scheduled", "Enqueued"].includes(ds.lastState || "") &&
        ds.ingestionType !== IngestionType.ManualFileUpload,
      mappingTypesLabel: ds.mappingTypes.join(", "),
    }));

    this.dataSources = dataSources;
  }

  private getState(sourceConfiguration: SourceConfigurationListItemDto) {
    if (!sourceConfiguration.lastState) {
      return "Pending";
    }

    if (sourceConfiguration.lastState === "SucceededWithWarning") {
      return "Warnings";
    }

    return sourceConfiguration.lastState;
  }

  async onDataSourceSaved() {
    await this.getAll();
    await this.$refs.dataSourceHistoryComponent?.getHistory();
  }

  async deleteItem(dataSourceId: string) {
    await api.dataservice.dataSourceService.delete(dataSourceId);
    await this.getAll();
  }

  async triggerItem(dataSourceId: string) {
    await api.dataservice.dataSourceService.trigger(dataSourceId);
    await this.getAll();
  }

  private fromNow(dateTime: string | null) {
    if (!dateTime) {
      return null;
    }

    return moment(dateTime).fromNow();
  }

  private formatDate(dateTime: string | null) {
    if (!dateTime) {
      return null;
    }

    return moment(dateTime).local().format("DD MMM YYYY, HH:mm:ss");
  }

  private getChipColor(sourceConfiguration: SourceConfigurationListItemDto) {
    switch (sourceConfiguration.lastState) {
      case "Enqueued":
      case "Processing":
      case "Scheduled":
        return "purple";
      case "Succeeded":
        return "green";
      case "SucceededWithWarning":
        return "orange";
      case "Failed":
        return "red";
      default:
        return "gray";
    }
  }

  private getCronExpressionDescription(cronExpression: string) {
    for (const [, cron] of Object.entries(constants.cronExpressions)) {
      if (cron.value === cronExpression) {
        return cron.text;
      }
    }
    return "Unknown Cron";
  }
}

interface SourceConfigurationItemViewModel extends SourceConfigurationListItemDto {
  state: string;
  lastStateColor: string | null;
  lastExecutionFromNow: string | null;
  nextExecutionFromNow: string | null;
  cronExpressionDescription: string;
  canBeTriggered: boolean;
  tooltipHtml: string;
  mappingTypesLabel: string;
}
