
import { Component, Vue } from "vue-property-decorator";
import AppHeader from "@/components/header.vue";
import ProgressBar from "@/components/progress.bar.vue";
import SnackBar from "@/components/snack.bar.vue";

@Component({
  components: {
    AppHeader,
    ProgressBar,
    SnackBar,
  },
})
export default class App extends Vue {}
