
import { Component, Prop, Vue } from "vue-property-decorator";
import { Validators } from "@/helpers";
import {
  DatahubIngestionSettingDto,
  TenantDto,
  CachedTenantsServiceInstance,
} from "@/services/dataservice";
import { api } from "@/services";
import { DataProviderDataSourcesDto, DataSourceProviderDto } from "@/services/datahub";
import { eventHub } from "@/eventhub";

@Component
export default class IngestionTypeDatahub extends Vue {
  @Prop() ingestionSetting: DatahubIngestionSettingDto;
  dataProvidersWithConnectedSources: DataProviderDataSourcesDto[] = [];
  tenants: TenantDto[] = [];
  currentDataSourceList: (DataSourceProviderDto | null)[] = [];
  rules = {
    required: Validators.Required.Text,
  };

  @Prop({ default: "Data source" }) dataSourceLabel: string;

  async mounted() {
    await this.getTenants();
    if (this.ingestionSetting.tenantId) {
      await this.loadTenantDataSources(this.ingestionSetting.tenantId);
    }
  }
  async tenantSelectionChange(tenantId: string) {
    await this.loadTenantDataSources(tenantId);
  }
  private async loadTenantDataSources(tenantId: string) {
    let response = await api.datahub.tenantService.dataSources(tenantId);
    if (response.isSuccess) {
      this.dataProvidersWithConnectedSources = response.result;
      this.updateDataSourceSelection(this.ingestionSetting.dataProviderId)
    } else {
      eventHub.$emit(
        "notification",
        "An error occurred retrieving the list of data sources for tenant"
      );
    }
  }

  async getTenants() {
    const tenantResult = await CachedTenantsServiceInstance.getTenants();
    if (tenantResult.isFailure) {
      eventHub.$emit("notification", "An error occurred retrieving the list of tenants");
      return;
      }

    this.tenants = tenantResult.result;
  }

  updateDataSourceSelection(dataProviderId: string) {
    this.currentDataSourceList =
      this.dataProvidersWithConnectedSources
        .filter((x) => x.id === dataProviderId)
        .flatMap((x) => x.dataSources) ?? [];
  }
}
