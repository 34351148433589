import { MappingConfiguration } from "./index";

export const Company: MappingConfiguration = {
  schema: [
    {
      elementName: "Name",
      schema: null,
    },
    {
      elementName: "IdentificationNumber",
      schema: null,
    },
    {
      elementName: "LegalForm",
      schema: null,
    },
    {
      elementName: "Address",
      schema: [
        { elementName: "AddressLine", schema: null },
        { elementName: "City", schema: null },
        { elementName: "Country", schema: null },
        { elementName: "CountryIso", schema: null },
        { elementName: "HouseNumber", schema: null },
        { elementName: "HouseNumberAddition", schema: null },
        { elementName: "LocationDescription", schema: null },
        { elementName: "Postcode", schema: null },
        { elementName: "Province", schema: null },
        { elementName: "State", schema: null },
        { elementName: "Street", schema: null },
      ],
    },
    {
      elementName: "BankAccountNumber",
      schema: null,
    },
    {
      elementName: "Phone",
      schema: null,
    },
    {
      elementName: "Email",
      schema: null,
    },
    {
      elementName: "CustomId",
      schema: null,
    },
    {
      elementName: "CocNumber",
      schema: null,
    },
    {
      elementName: "DunsNumber",
      schema: null,
    },
    {
      elementName: "RegistrationNumber",
      schema: null,
    },
    {
      elementName: "VatNumber",
      schema: null,
    },
    {
      elementName: "TaxNumber",
      schema: null,
    },
  ],
  validationRules: {
    default: [
      {
        elementName: "Name",
        required: true,
      },
    ],
  },
};
