import { constants } from "@/services/constants";
import {
  CustomIngestionType,
  DataFormatType,
  DecryptionAlgorithmType,
  IngestionType,
} from "./dataservice";
import { MappingConfiguration, Templates } from "@/models/mappings";

class LookupService {
  get restrictions() {
    return [
      {
        text: "Public",
        value: "Public",
      },
      {
        text: "Private",
        value: "Private",
      },
    ];
  }

  get dataFormats() {
    return [
      {
        text: "XML",
        value: DataFormatType.Xml,
      },
      {
        text: "JSON",
        value: DataFormatType.Json,
      },
      {
        text: "CSV",
        value: DataFormatType.Csv,
      },
      {
        text: "Excel",
        value: DataFormatType.Excel,
      },
    ];
  }

  get dataSourceTypes() {
    return [
      {
        text: "URL",
        value: IngestionType.Url,
      },
      {
        text: "SFTP",
        value: IngestionType.SFTP,
      },
      {
        text: "Local upload",
        value: IngestionType.ManualFileUpload,
      },
      {
        text: "Datahub",
        value: IngestionType.Datahub,
      },
      {
        text: "Custom",
        value: IngestionType.Custom,
      },
    ];
  }

  get decryptionAlgorithmTypes() {
    return [
      {
        text: "None",
        value: DecryptionAlgorithmType.None,
      },
      {
        text: "PGP keys",
        value: DecryptionAlgorithmType.Pgp,
      },
    ];
  }

  get customIngestionTypes() {
    return [
      {
        text: "ETars",
        value: CustomIngestionType.ETars,
      },
      {
        text: "IFB",
        value: CustomIngestionType.IFB,
      },
    ];
  }

  get cronExpressions(): Array<{ text: string; value: string }> {
    return [
      constants.cronExpressions.never,
      constants.cronExpressions.everyMinute,
      constants.cronExpressions.hourly,
      constants.cronExpressions.daily,
      constants.cronExpressions.weekly,
      constants.cronExpressions.monthly,
    ];
  }

  get dataSourceMappingType(): Array<{
    text: string;
    value: string;
    configuration: MappingConfiguration;
  }> {
    return [
      {
        text: "Company",
        value: "Company",
        configuration: Templates.Company,
      },
      {
        text: "Policy",
        value: "Policy",
        configuration: Templates.Policy,
      },
      {
        text: "Person",
        value: "Person",
        configuration: Templates.Person,
      },
      {
        text: "Vehicle",
        value: "Vehicle",
        configuration: Templates.Vehicle,
      },
    ];
  }

  canHaveMappings(dataFormatType: DataFormatType) {
    const dataFormatsThatSupportsMappings = [DataFormatType.Csv, DataFormatType.Excel];
    return dataFormatsThatSupportsMappings.includes(dataFormatType);
  }
}

export default new LookupService();
