import { ApiResult } from "@/nswag";
import { TenantDto, TenantsService } from "./dataservice-api/dataservice-api.generated";

class CachedTenantsService extends TenantsService {
  private cache: TenantDto[] | null = null;

  async getTenants(): Promise<ApiResult<TenantDto[]>> {
    if (this.cache !== null) {
      return ApiResult.Success(this.cache);
    }

    const tenants = await super.getTenants();

    if (tenants.isFailure) {
      return ApiResult.Failure();
    }

    this.cache = tenants.result;

    return ApiResult.Success(this.cache);
  }
}

export const CachedTenantsServiceInstance = new CachedTenantsService();
