
import { Component, Vue } from "vue-property-decorator";
import { eventHub } from "@/eventhub";

@Component
export default class SnackBar extends Vue {
  snackbar = false;
  text = "";

  showSnackBar(errorMessage: string) {
    this.snackbar = true;
    this.text = errorMessage;
  }

  created() {
    eventHub.$on("notification", this.showSnackBar);
  }

  beforeDestroy() {
    eventHub.$off("notification", this.showSnackBar);
  }
}
