import LZString from "lz-string";

export class SessionStorage {
  setItem(key: string, value: string) {
    const value_ = LZString.compressToEncodedURIComponent(value);
    const key_ = LZString.compressToEncodedURIComponent(key);
    sessionStorage.setItem(key_, value_);
  }

  getItem(key: string) {
    const key_ = LZString.compressToEncodedURIComponent(key);
    let result = sessionStorage.getItem(key_);
    if (result) {
      result = LZString.decompressFromEncodedURIComponent(result);
    }
    return result;
  }

  removeItem(key: string) {
    const key_ = LZString.compressToEncodedURIComponent(key);
    sessionStorage.removeItem(key_);
  }

  key(index: number) {
    return LZString.decompressFromEncodedURIComponent(sessionStorage.key(index)!);
  }
}
