
import { Component, Vue } from "vue-property-decorator";
import mainAuth from "@/auth";

@Component
export default class AppHeader extends Vue {
  isSignout = false;

  get isAuthenticated() {
    return mainAuth.isAuthenticated;
  }

  get emailAddress() {
    return mainAuth.userProfile[
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
    ];
  }

  signOut() {
    this.isSignout = true;
    mainAuth.signOut();
  }
}
