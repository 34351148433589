
import { Component, Prop, Vue } from "vue-property-decorator";
import Tooltip from "@/components/tooltip.vue";
import SecureString from "@/components/secure-string.vue";
import {
  IngestionType,
  DataSourceService,
  UploadFileToAzureBlobStorageResponse,
  ManuallyUpdatedFileSourceDto,
} from "@/services/dataservice";

import { VuetifyControl } from "@/plugins/vuetify";
import { uploadFile } from "@/nswag/nswag-base-class";
import { Validators } from "@/helpers";

@Component({
  components: {
    Tooltip,
    SecureString,
  },
})
export default class FileSourceLocation extends Vue {
  @Prop() fileSource: ManuallyUpdatedFileSourceDto;
  @Prop() label: string;

  rules = {
    file: [...Validators.Required.FileWithMaxSize(1024), this.ensureUploadIsComplete],
  };

  $refs: {
    fileInput: VuetifyControl;
  };

  isUploadComplete: boolean | null = null;

  IngestionType = IngestionType;

  uploadProgress: { progress: number; error: string | null } = {
    progress: 0,
    error: null,
  };

  dataSourceService = new DataSourceService();

  file: File | null = null;

  get progressBarColor() {
    if (this.uploadProgress.progress === 100) {
      return "light-green";
    }

    return "light-blue";
  }

  get isUploading() {
    return this.uploadProgress.progress > 0 && this.uploadProgress.progress < 100;
  }

  mounted() {
    this.file = { name: this.fileSource.fileName, size: this.fileSource.fileName ? 1 : 0 } as File;
  }

  async onFileUpload(file: File | undefined, fileSource: ManuallyUpdatedFileSourceDto) {
    if (!this.$refs.fileInput.validate()) {
      return;
    }

    this.isUploadComplete = false;

    if (!file) {
      fileSource.blobName = "";
      fileSource.fileName = "";

      this.updateProgress(0);

      return;
    }

    const uploadResult = await uploadFile<UploadFileToAzureBlobStorageResponse>(
      "datasources/upload",
      { formFile: file },
      (progress) => {
        this.updateProgress(progress <= 99 ? progress : 99);
      }
    );

    if (uploadResult.isFailure) {
      this.updateProgress(0, `The upload of '${file.name}' failed. Please try again.`);

      fileSource.blobName = "";
      fileSource.fileName = "";

      this.file = { name: "", size: 0 } as File;

      this.$emit("onFileUpload", undefined);
      return;
    }

    this.updateProgress(100);

    fileSource.blobName = uploadResult.result.azureBlobStorageName;
    fileSource.fileName = file.name;

    this.isUploadComplete = true;
    this.$refs.fileInput.validate();

    this.$emit("onFileUpload", file);
  }

  private updateProgress(progress: number, error: string | null = null) {
    this.uploadProgress = {
      error,
      progress,
    };
    this.uploadProgress = { ...this.uploadProgress };
  }

  private ensureUploadIsComplete() {
    return this.isUploadComplete === false ? "The upload is not complete." : true;
  }
}
