import { datahub } from "./datahub";
import { dataservice } from "./dataservice";

export { dataservice } from "./dataservice";
export { datahub } from "./datahub";

export const api = {
  dataservice: dataservice,
  datahub: datahub,
};

export type SecureStringContainer = { [guid: string]: string };
