
import { Validators } from "@/helpers";
import lookupService from "@/services/lookup.service";
import { constants } from "@/services/constants";
import { SchemaMappingDto, SchemaDto } from "@/services/dataservice";
import { VuetifyForm } from "@/plugins/vuetify";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Templates } from "@/models/mappings";
import { eventHub } from "@/eventhub";

@Component
export default class SingleMapping extends Vue {
  @Prop() schemaMapping!: SchemaMappingDto;
  @Prop() schema!: SchemaDto;

  isFormValid = false;
  lookup = lookupService;
  constants = constants;

  rules = {
    sourceMappingFieldRequired: Validators.Required.ValueWithCustomMessage,
    mappingType: Validators.Required.Value,
  };

  /** Fields derived from current source configuration schema */
  sourceFields: Array<string> = [];

  /** Fields derived from chosen Standard Data Model */
  destinationFields: Array<{ name: string }> = [];

  /** Mapping SDM -> Schema */
  mappingModel: { [destinationFromSdm: string]: string | null } = {};

  $refs!: {
    form?: VuetifyForm;
  };

  async mounted() {
    this.initialize();
  }

  @Watch("schemaMapping")
  @Watch("schema")
  private initialize() {
    this.sourceFields = this.flatten(this.schema)
      .map((i) => i.name)
      .sort();

    this.initMappingType(this.schemaMapping.type);
  }

  onSourceChange(correspondingDestinationPath: string) {
    if (!this.mappingModel[correspondingDestinationPath]) {
      delete this.mappingModel[correspondingDestinationPath];
    }

    if (!this.$refs.form?.validate()) {
      return;
    }

    const selectedSourceFields: Array<{ sourcePath: string | null; destinationPath: string }> =
      Object.entries(this.mappingModel).map(([destinationPath, sourcePath]) => {
        return {
          sourcePath,
          destinationPath,
        };
      });

    const emptySourceFields: Array<{ sourcePath: string | null; destinationPath: string }> =
      this.destinationFields
        .filter((destination) =>
          selectedSourceFields.every((selected) => selected.destinationPath !== destination.name)
        )
        .map((destination) => ({
          sourcePath: null,
          destinationPath: destination.name,
        }));

    const mapping: SchemaMappingDto = {
      type: this.schemaMapping.type,
      mappings: [...selectedSourceFields, ...emptySourceFields], // save not see
    };

    this.$emit("onMappingUpdated", mapping);
  }

  onMappingTypeChanged(mappingType: string) {
    const emptyMapping: SchemaMappingDto = {
      type: mappingType,
      mappings: [],
    };
    this.$emit("onMappingUpdated", emptyMapping);
  }

  getAvailableSourceFields(selectedItem: string | null) {
    const selectedSourceFields = Object.entries(this.mappingModel).map(
      ([, sourcePath]) => sourcePath
    );

    const availableSourceFields = this.sourceFields.filter(
      (field) => !selectedSourceFields.includes(field)
    );

    if (selectedItem) {
      return [selectedItem, ...availableSourceFields].sort();
    }

    return availableSourceFields.sort();
  }

  private initMappingType(mappingType: string) {
    const destinationSchema = lookupService.dataSourceMappingType.find(
      (m) => m.value === mappingType
    );

    if (!destinationSchema) {
      const message = `Mapping type '${mappingType}' not found`;
      eventHub.$emit("notification", message);
      console.error(message);
      return;
    }

    this.destinationFields = this.flatten(destinationSchema.configuration.schema);

    const mappingModel: { [destinationFromSdm: string]: string | null } = {};
    for (const mapping of this.schemaMapping.mappings) {
      if (!this.destinationFields.find((f) => f.name === mapping.destinationPath)) {
        const message = `Mapping '${mapping.destinationPath}' not found in type ${mappingType}`;
        eventHub.$emit("notification", message);
        console.error(message);
        continue;
      }

      mappingModel[mapping.destinationPath] = mapping.sourcePath;
    }

    this.mappingModel = mappingModel;

    this.$refs.form?.resetValidation();
  }

  private flatten(schema: SchemaDto) {
    type FlattenedItemInternal = { name: string; parent: string[]; required: boolean | string };

    const flattenInternal = (
      schema: SchemaDto,
      parent: string[] = []
    ): Array<FlattenedItemInternal> => {
      return schema.reduce((acc: FlattenedItemInternal[], r) => {
        if (r.schema && r.schema.length) {
          acc = acc.concat(flattenInternal(r.schema, parent.concat(r.elementName)));
        } else {
          acc.push({ name: r.elementName, parent: parent, required: false });
        }

        return acc;
      }, []);
    };

    const flattenedSchema = flattenInternal(schema).map((item) => {
      return {
        name: item.parent.join(".") + (item.parent.length > 0 ? "." : "") + item.name,
        required: item.required,
      };
    });
    //.sort((a, b) => (a.name > b.name ? 1 : -1));

    const flattenedSchemaNames = flattenedSchema.map((s) => s.name);
    if (new Set(flattenedSchemaNames).size < flattenedSchema.length) {
      const message =
        "One more mapping mapping fields are duplicated: " + flattenedSchemaNames.join(",");
      eventHub.$emit("notification", message);
      throw Error(message);
    }

    return flattenedSchema;
  }

  public validate(): { hasErrors: boolean; errorMessage?: string } {
    const selectedSourceFields: Array<{ sourcePath: string | null; destinationPath: string }> =
      Object.entries(this.mappingModel).map(([destinationPath, sourcePath]) => {
        return {
          sourcePath,
          destinationPath,
        };
      });

    const currentMappingConfiguration =
      Templates[this.schemaMapping.type as keyof typeof Templates];

    for (const [, validationRules] of Object.entries(currentMappingConfiguration.validationRules)) {
      const isValid = validationRules
        .filter((r) => r.required)
        .every(
          (r) => !!selectedSourceFields.find((f) => f.destinationPath === r.elementName)?.sourcePath
        );

      if (isValid) {
        return { hasErrors: false };
      }
    }

    let requiredFields = Object.entries(currentMappingConfiguration.validationRules)
      .map(([, validationRules]) =>
        validationRules
          .filter((m) => m.required)
          .map((m) => m.elementName)
          .join(`,`)
      )
      .map((e) => `<li style="margin-left: 15px">${e}</li>`)
      .join("\n");

    return {
      hasErrors: true,
      errorMessage: `<div>Please fill in a combination of the following required fields in the mappings tab: <ul>${requiredFields}</ul></div>`,
    };
  }
}
