export * from "./dataservice-api/dataservice-api.generated";
export * from "./cached-tenants.service";
import {
  SearchService,
  SchemaService,
  TenantsService,
  DataSourceService,
  SchemaElementDto,
} from "./dataservice-api/dataservice-api.generated";

export type SchemaDto = SchemaElementDto[];
export const dataservice = {
  dataSourceService: new DataSourceService(),
  SearchService: new SearchService(),
  tenantsService: new TenantsService(),
  schemaService: new SchemaService(),
};
