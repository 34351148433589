
import { Component, Prop, Vue } from "vue-property-decorator";
import { DownloadSettingDto } from "@/services/dataservice";
import Tooltip from "@/components/tooltip.vue";
import { Validators } from "@/helpers";

@Component({
  components: {
    Tooltip,
  },
})
export default class DataFormatXml extends Vue {
  @Prop() downloadSetting: DownloadSettingDto;

  rules = {
    collectionElementName: Validators.Required.Text,
  };
}
