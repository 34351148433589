import Vue from "vue";
import { createOidcAuth, SignInType, LogLevel } from "vue-oidc-client";
import { WebStorageStateStore, UserManagerSettings } from "oidc-client";
import runtimeConfig from "./runtime_config";
import { SessionStorage } from "./session-storage";

function getAppRootUrl() {
  return `${window.location.protocol}//${window.location.host}${process.env.BASE_URL}`;
}

const OIDC_SETTINGS: UserManagerSettings = {
  authority: runtimeConfig.authority,
  client_id: runtimeConfig.clientId,
  response_type: "id_token token",
  scope: "openid profile screening datahub",
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}`,
  userStore: new WebStorageStateStore({ store: new SessionStorage() }),
  revokeAccessTokenOnSignout: true,
};

const mainOidc = createOidcAuth(
  "main",
  SignInType.Window,
  getAppRootUrl(),
  OIDC_SETTINGS,
  console,
  LogLevel.Info
);

Vue.prototype.$oidc = mainOidc;
(window as any).$oidc = mainOidc;

export default mainOidc;
