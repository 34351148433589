import { MappingConfiguration } from "./index";

export const Policy: MappingConfiguration = {
  schema: [
    {
      elementName: "Product",
      schema: null,
    },
    {
      elementName: "PolicyId",
      schema: null,
    },
    {
      elementName: "InceptionDate",
      schema: null,
    },
    {
      elementName: "EndDate",
      schema: null,
    },
    {
      elementName: "YearsWithoutClaim",
      schema: null,
    },
    {
      elementName: "InsuredAmount",
      schema: null,
    },
    {
      elementName: "IncurredAmount",
      schema: null,
    },
    {
      elementName: "AnnualPremium",
      schema: null,
    },
    {
      elementName: "Channel",
      schema: null,
    },
    {
      elementName: "Type",
      schema: null,
    },
    {
      elementName: "SigningDate",
      schema: null,
    },
    {
      elementName: "PreviousExpiryDate",
      schema: null,
    },
    {
      elementName: "RenewalDate",
      schema: null,
    },
    {
      elementName: "AmountExcess",
      schema: null,
    },
    {
      elementName: "PaymentFrequency",
      schema: null,
    },
    {
      elementName: "PaymentType",
      schema: null,
    },
    {
      elementName: "LossRatio",
      schema: null,
    },
    {
      elementName: "CustomId",
      schema: null,
    },
  ],
  validationRules: {
    default: [
      {
        elementName: "Product",
        required: true,
      },
    ],
  },
};
