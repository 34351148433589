
import { Component, Prop, Vue } from "vue-property-decorator";
import lookupService from "@/services/lookup.service";
import { constants } from "@/services/constants";
import { Validators } from "@/helpers";
import FileSourceLocation from "@/views/datasource/ingestion-types/filesource-location.vue";
import SecureString from "@/components/secure-string.vue";
import { IngestionType, DownloadSettingDto, SftpIngestionSettingDto } from "@/services/dataservice";
import { SecureStringContainer } from "@/services";

@Component({
  components: {
    FileSourceLocation,
    SecureString,
  },
})
export default class IngestionTypeSftp extends Vue {
  @Prop() downloadSetting: DownloadSettingDto & { ingestionSetting: SftpIngestionSettingDto };
  @Prop() secureStringContainer: SecureStringContainer;

  lookup = lookupService;
  constants = constants;
  username = "";
  password = "";
  hasExistingCredentials = false;
  rules = {
    host: Validators.Required.Text,
    port: Validators.Required.Number,
    fileName: Validators.Required.Text,
    username: Validators.Required.Text,
    password: Validators.Required.Text,
  };

  get ingestionSetting() {
    return this.downloadSetting.ingestionSetting as SftpIngestionSettingDto;
  }

  created() {
    this.downloadSetting.ingestionSetting ??= {
      type: IngestionType.SFTP,
      fileSources: [],
      host: "",
      password: "",
      port: 22,
      username: "",
    } as SftpIngestionSettingDto;

    this.hasExistingCredentials =
      this.ingestionSetting.username != null || this.ingestionSetting.password != null;
  }

  destroyed() {
    if (this.downloadSetting.ingestionSetting.type === IngestionType.Url) {
      // just changed from Sftp to Url, remove sftp passwords from container
      delete this.secureStringContainer[this.ingestionSetting.username];
      delete this.secureStringContainer[this.ingestionSetting.password];
    }
  }
}
