
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  CustomIngestionSettingDto,
  IngestionType,
  DownloadSettingDto,
  SftpIngestionSettingDto,
} from "@/services/dataservice";
import IngestionTypeSftp from "@/views/datasource/ingestion-types/ingestion-type-sftp.vue";
import { SecureStringContainer } from "@/services";

@Component({
  components: {
    IngestionTypeSftp,
  },
})
export default class IngestionTypeIfb extends Vue {
  @Prop() secureStringContainer: SecureStringContainer;
  @Prop() downloadSettings: DownloadSettingDto & {
    ingestionSetting: CustomIngestionSettingDto & {
      ingestionSettings: {
        ingestionSetting: SftpIngestionSettingDto;
      };
    };
  };

  async created() {
    this.downloadSettings.ingestionSetting.ingestionSettings ??= {
      ingestionSetting: {
        type: IngestionType.SFTP,
        fileSources: [],
        host: "",
        password: "",
        port: 22,
        username: "",
      },
    };
  }
}
