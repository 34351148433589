export * from "./datahub-api/datahub-api.generated";

// Export service instances
import {
  DataSourceService,
  ConnectionService,
  TenantService,
  UserService,
  InvokeService,
  ExpressionService,
  DataProviderService,
  DataSourceResponseService,
} from "./datahub-api/datahub-api.generated";

export const datahub = {
  dataSourceService: new DataSourceService(),
  connectionService: new ConnectionService(),
  tenantService: new TenantService(),
  userService: new UserService(),
  invokeService: new InvokeService(),
  expressionService: new ExpressionService(),
  dataProviderService: new DataProviderService(),
  dataSourceResponseService: new DataSourceResponseService(),
};
