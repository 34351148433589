
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  PgpDecryptionSettingsDto,
  SourceConfigurationDto,
} from "@/services/dataservice";
import lookupService from "@/services/lookup.service";
import SecureString from "@/components/secure-string.vue";
import SecureFile from "@/components/secure-base64-file.vue";

@Component({
  components: {
    SecureString,
    SecureFile,
  },
  methods: {
    lookupService() {
      return lookupService;
    },
  },
})
export default class PgpDecryptionSettingComponent extends Vue {
  @Prop() sourceConfiguration: SourceConfigurationDto;

  get decryptionSettings() {
    return this.sourceConfiguration.downloadSetting.decryptionSettings as PgpDecryptionSettingsDto;
  }

  onKeyUploaded(key: File | undefined) {
    if (!key) {
      this.decryptionSettings.privateKeyFileName = "";
    } else {
      this.decryptionSettings.privateKeyFileName = key.name;
    }
  }
}
