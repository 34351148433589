
import {
  SchemaElementDto,
  SourceConfigurationDto,
  SchemaService,
  DataFormatType,
} from "@/services/dataservice";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Validators } from "@/helpers";
import { eventHub } from "@/eventhub";
import lookupService from "@/services/lookup.service";

const schemaService = new SchemaService();

@Component
export default class DataSourceSchema extends Vue {
  @Prop() dataSource: SourceConfigurationDto;
  DataFormatType = DataFormatType;
  lookupService = lookupService;

  schema: Array<SchemaElementDto> | null = null;

  delimiter = ",";

  rules = {
    delimiter: Validators.Required.Text,
  };

  $refs: {
    schemaFile: Vue & { reset: () => void; blur: () => void };
  };

  isLoading = false;

  mounted() {
    this.initialize();
  }

  @Watch("dataSource")
  private initialize() {
    this.schema = this.EnsureSchemaHasNullValue(this.dataSource.schema, []);
  }

  async onSchemaFileUpload(file: File) {
    if (!file) {
      this.$emit("onSchemaUploaded", null);
      return;
    }

    this.isLoading = true;

    const apiResult = await schemaService.generateSchemaFromCsvFile(
      undefined,
      { fileName: file.name, data: new Blob([file]) },
      this.delimiter
    );

    this.isLoading = false;

    if (apiResult.isFailure) {
      const message =
        "There was an error trying to parse the provided CSV file. Please ensure the delimiter is correct.";
      this.$refs.schemaFile.reset();
      this.$refs.schemaFile.blur();
      eventHub.$emit("notification", message);
      this.$emit("onSchemaUploaded", null);
      return;
    }

    this.schema = apiResult.result;
    this.$emit("onSchemaUploaded", this.EnsureSchemaHasNullValue(this.schema, null));
  }

  /** Vuetify teeview throws if the children navigation property (schema) is null instead of [] */
  private EnsureSchemaHasNullValue(
    schema: SchemaElementDto[] | null,
    nullValue: SchemaElementDto[] | null
  ) {
    const ensureSchemaHasNullValueInternal = (
      schema: SchemaElementDto[] | null,
      nullValue: SchemaElementDto[] | null
    ) => {
      for (const s of schema || []) {
        if (s.schema === null || s.schema === undefined || s.schema.length === 0) {
          s.schema = nullValue;
        }

        ensureSchemaHasNullValueInternal(s.schema, nullValue);
      }
    };

    const schemaCopy: SchemaElementDto[] = JSON.parse(JSON.stringify(schema || []));
    ensureSchemaHasNullValueInternal(schemaCopy, nullValue);
    return schemaCopy;
  }
}
