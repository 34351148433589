import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import mainAuth from "./auth";
import "./global-components";

Vue.config.productionTip = false;

mainAuth.startup().then((ok) => {
  if (ok) {
    new Vue({
      vuetify,
      router,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
