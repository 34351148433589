
import { Component, Prop, Vue } from "vue-property-decorator";
import { Validators } from "@/helpers";
import FileSourceLocation from "@/views/datasource/ingestion-types/filesource-location.vue";
import { DownloadSettingDto } from "@/services/dataservice";
import { SecureStringContainer } from "@/services";

@Component({
  components: {
    FileSourceLocation,
  },
})
export default class IngestionTypeUrl extends Vue {
  @Prop() downloadSetting: DownloadSettingDto;
  @Prop() secureStringContainer: SecureStringContainer;

  rules = {
    url: Validators.Required.Url,
  };
}
