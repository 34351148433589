
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Tooltip from "@/components/tooltip.vue";
import { Validators } from "@/helpers";
import { NotificationSettingDto } from "@/services/dataservice";

@Component({
  components: {
    Tooltip,
  },
})
export default class DataSourceMonitorSettings extends Vue {
  @Prop() notificationSettings: NotificationSettingDto[];

  notificationsReminderDate: Array<{ showDatePicker: boolean }> = [];

  @Watch("notificationSettings", { deep: true, immediate: true })
  onNotificationSettingsChanged(newValue: NotificationSettingDto[]) {
    this.notificationsReminderDate = newValue.map((n) => {
      return { showDatePicker: !!n.reminderDate };
    });
  }

  rules = {
    name: Validators.Required.Text,
  };

  add() {
    this.notificationSettings.push({
      email: "",
      onFailure: false,
      onSuccess: false,
      reminderDate: "",
    });
  }

  remove(index: number) {
    this.notificationSettings.splice(index, 1);
  }

  get notificationsSummary() {
    const areNotificationsEnabled = this.notificationSettings.some(
      (n) => n.onFailure || n.onSuccess
    );

    let summary = "Notifications " + (areNotificationsEnabled ? "enabled" : "disabled");

    if (areNotificationsEnabled) {
      summary += ` on ${
        this.notificationSettings.filter((n) => n.onFailure || n.onSuccess || n.reminderDate).length
      } email(s)`;
    }

    return summary;
  }

  clearDate(index: number) {
    this.notificationSettings[index].reminderDate = null;
  }

  today() {
    return new Date().toISOString().slice(0, 10);
  }

  onShowDatePickerChange(value: boolean, index: number) {
    if (!value) {
      this.clearDate(index);
    }
  }
}
