
import { Component, Prop, Vue } from "vue-property-decorator";
import { SourceConfigurationHistoryDto } from "@/services/dataservice";
import * as monaco from "monaco-editor";
import moment from "moment";
import { api } from "@/services";

@Component
export default class DataSourceHistoryCompare extends Vue {
  @Prop() left: SourceConfigurationHistoryDto | null;
  @Prop() right: SourceConfigurationHistoryDto;

  isOpen = true;

  private diffEditor: monaco.editor.IStandaloneDiffEditor;

  async mounted() {
    const editorDiv = document.getElementById("editor")!;

    const comparison = await api.dataservice.dataSourceService.getHistoryComparison(
      this.right.sourceConfigurationId,
      this.left?.historyEntryId,
      this.right.historyEntryId
    );

    if (comparison.isFailure) {
      return;
    }

    const leftModel = this.createModel(comparison.result.originalJson || "");
    const rightModel = this.createModel(comparison.result.modifiedJson);

    this.diffEditor = monaco.editor.createDiffEditor(editorDiv, {
      minimap: { enabled: true },
      scrollBeyondLastLine: false,
      automaticLayout: true,
      readOnly: true,
      theme: "vs-dark",
    });

    this.diffEditor.setModel({ original: leftModel, modified: rightModel });

    (window as any).diffEditor = this.diffEditor;
  }

  destroyed() {
    delete (window as any).diffEditor;
  }

  close() {
    this.$emit("close");
  }

  private createModel(json: string) {
    return monaco.editor.createModel(this.formatJson(json), "json");
  }

  private formatJson(json: string) {
    try {
      return JSON.stringify(JSON.parse(json), null, 2);
    } catch {
      return json;
    }
  }

  formatDate(dateTime: string | null | undefined) {
    if (!dateTime) {
      return "";
    }

    return moment(dateTime).local().format("DD MMM YYYY, HH:mm");
  }
}
