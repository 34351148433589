
import { Validators } from "@/helpers";
import {
  CachedTenantsServiceInstance,
  SourceConfigurationDto,
  TenantDto,
} from "@/services/dataservice";
import { eventHub } from "@/eventhub";

import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class RestrictionPrivate extends Vue {
  @Prop() sourceConfiguration!: SourceConfigurationDto;

  tenants: Array<TenantDto> = [];
  rules = {
    tenants: [Validators.Array.Min(1)],
  };

  async mounted() {
    const tenantResult = await CachedTenantsServiceInstance.getTenants();

    if (tenantResult.isFailure) {
      eventHub.$emit("notification", "An error occurred retrieving the list of tenants");
      return;
    }

    this.tenants = tenantResult.result;
  }
}
