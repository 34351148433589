import { MappingConfiguration } from "./index";

export const Person: MappingConfiguration = {
  schema: [
    {
      elementName: "FirstName",
      schema: null,
    },
    {
      elementName: "LastName",
      schema: null,
    },
    {
      elementName: "BirthDate",
      schema: null,
    },
    {
      elementName: "IdentificationType",
      schema: null,
    },
    {
      elementName: "IdentificationValue",
      schema: null,
    },
    {
      elementName: "FullName",
      schema: null,
    },
    {
      elementName: "BirthCountryCode",
      schema: null,
    },
    {
      elementName: "Gender",
      schema: null,
    },
    {
      elementName: "Initials",
      schema: null,
    },
    {
      elementName: "MaritalStatus",
      schema: null,
    },
    {
      elementName: "Nationality",
      schema: null,
    },
    {
      elementName: "Occupation",
      schema: null,
    },
    {
      elementName: "Prefix",
      schema: null,
    },
    {
      elementName: "Address",
      schema: [
        { elementName: "AddressLine", schema: null },
        { elementName: "City", schema: null },
        { elementName: "Country", schema: null },
        { elementName: "CountryIso", schema: null },
        { elementName: "HouseNumber", schema: null },
        { elementName: "HouseNumberAddition", schema: null },
        { elementName: "LocationDescription", schema: null },
        { elementName: "Postcode", schema: null },
        { elementName: "Province", schema: null },
        { elementName: "State", schema: null },
        { elementName: "Street", schema: null },
      ],
    },
    {
      elementName: "BankAccountNumber",
      schema: null,
    },
    {
      elementName: "Phone",
      schema: null,
    },
    {
      elementName: "Email",
      schema: null,
    },
    {
      elementName: "CustomId",
      schema: null,
    },
  ],
  validationRules: {
    default: [
      {
        elementName: "FirstName",
        required: true,
      },
      {
        elementName: "LastName",
        required: true,
      },
      {
        elementName: "BirthDate",
        required: true,
      },
    ],
    identificationOnly: [
      {
        elementName: "IdentificationValue",
        required: true,
      },
    ],
  },
};
