import Vue from "vue";
import Router from "vue-router";
import DataSources from "./views/datasource/datasources.vue";
import mainAuth from "./auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "datasources",
      component: DataSources,
      meta: {
        authName: mainAuth.authName,
      },
    },
  ],
});

mainAuth.useRouter(router);

export default router;
