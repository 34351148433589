
import { SchemaMappingDto, SourceConfigurationDto } from "@/services/dataservice";
import { Component, Prop, Vue } from "vue-property-decorator";
import SingleMapping from "./single-mapping.vue";

@Component({
  components: {
    SingleMapping,
  },
})
export default class DataSourceMapping extends Vue {
  @Prop() dataSource: SourceConfigurationDto;

  $refs: {
    [key: string]: SingleMapping;
  };

  addMapping() {
    this.$emit("onAddMappingClick");
  }

  removeMapping() {
    this.$emit("onRemoveMappingClick");
  }

  onMappingUpdated(mapping: SchemaMappingDto) {
    const mappings = [...this.dataSource.mappings!];

    mappings.splice(
      this.dataSource.mappings!.findIndex((m) => m.type === mapping.type),
      1,
      mapping
    );

    this.$emit("onMappingsUpdated", mappings);
  }

  validateForms(): { hasErrors: boolean, errorMessage?: string } {
    for (const mappingType in this.$refs) {
      const singleMapping = (
        this.$refs[mappingType] as unknown as Array<SingleMapping>
      )?.at(0);
            
      if (singleMapping) {
        return singleMapping.validate();
      }
    }

    return { hasErrors: false };
  }
}
