import { Company } from "./company";
import { Person } from "./person";
import { Policy } from "./policy";
import { Vehicle } from "./vehicle";
import {SchemaElementDto} from "@/services/dataservice";

export interface MappingConfigurationValidation {
  elementName: string;
  required: boolean;
}

export interface MappingConfiguration {
    schema: SchemaElementDto[];
    validationRules: { [validationName: string]: MappingConfigurationValidation[] }
}

export const Templates = {
  Company,
  Person,
  Policy,
  Vehicle
};
