
import { Component, Prop, Vue } from "vue-property-decorator";
import Tooltip from "@/components/tooltip.vue";
import SecureString from "@/components/secure-string.vue";
import ManualFileSource from "@/views/datasource/ingestion-types/filesource-manual.vue";
import {
  IngestionSettingDto,
  IngestionType,
  ManualFileUploadIngestionSettingDto,
  UrlIngestionSettingDto,
  SftpIngestionSettingDto,
} from "@/services/dataservice";
import { SecureStringContainer } from "@/services";

@Component({
  components: {
    Tooltip,
    SecureString,
    ManualFileSource,
  },
})
export default class FileSourceLocation extends Vue {
  @Prop() ingestionSetting:
    | SftpIngestionSettingDto
    | UrlIngestionSettingDto
    | ManualFileUploadIngestionSettingDto;
  @Prop() label: string;
  @Prop() rules: unknown[];
  @Prop() secureStringContainer: SecureStringContainer;

  IngestionType = IngestionType;

  addFileSource() {
    this.ingestionSetting.fileSources.push({
      password: null,
      blobName: "",
      fileName: "",
      location: "",
      url: "",
    });
    this.$forceUpdate();
  }

  removeFileSource(index: number) {
    this.ingestionSetting.fileSources.splice(index, 1);
    this.$forceUpdate();
  }

  async onFileUpload(file: File | undefined) {
    this.$emit("onFileUpload", file);
  }

  isIngestionUrl(
    ingestionSettings: IngestionSettingDto
  ): ingestionSettings is UrlIngestionSettingDto {
    return ingestionSettings.type === IngestionType.Url;
  }
  isIngestionSftp(
    ingestionSettings: IngestionSettingDto
  ): ingestionSettings is SftpIngestionSettingDto {
    return ingestionSettings.type === IngestionType.SFTP;
  }

  isIngestionManualFileUpload(
    ingestionSettings: IngestionSettingDto
  ): ingestionSettings is ManualFileUploadIngestionSettingDto {
    return ingestionSettings.type === IngestionType.ManualFileUpload;
  }

  created() {
    this.ingestionSetting.fileSources ??= [];
    if (this.ingestionSetting.fileSources.length == 0) {
      this.addFileSource();
    }
  }
}
