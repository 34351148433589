
import { Component, Prop, Vue } from "vue-property-decorator";
import { DecryptionAlgorithmType, SourceConfigurationDto } from "@/services/dataservice";
import lookupService from "@/services/lookup.service";
import SecureString from "@/components/secure-string.vue";
import PgpDecryptionSettingComponent from "@/views/datasource/decryption-settings/pgp-decryption-setting.vue";

@Component({
  computed: {
    DecryptionAlgorithmType() {
      return DecryptionAlgorithmType;
    },
  },
  components: {
    SecureString,
    PgpDecryptionSettingComponent,
  },
  methods: {
    lookupService() {
      return lookupService;
    },
  },
})
export default class DecryptionSettingsComponent extends Vue {
  @Prop() sourceConfiguration: SourceConfigurationDto;
}
